<template>
  <div class="p-1">
    <p class="d-flex justify-content-center saj-header">
      {{
        isUpdate ? $t("Update Head of Section") : $t("Add New Head of Section")
      }}
    </p>

    <validation-observer ref="validateSection">
      <label class="saj-title">{{ $t("Subsidiary") }}:</label>
      <validation-provider
        #default="{ errors }"
        :name="$t('Subsidiary')"
        rules="required"
      >
        <v-select
          v-model="selectedSubReg"
          class="saj-text select-size-md"
          style="background: white"
          :placeholder="$t('Choose Subsidiary')"
          :options="subList"
          :reduce="(sub) => sub.value"
          label="text"
          :disabled="roles.selectedRole === 1 && isUpdate"
          @input="getEmployeeName()"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
      <b-form-group
        class="saj-title mt-1"
        :label="$t('Choose Employee Number / Name')"
      >
        <!-- Employee ID or Employee Name Button -->
        <b-button
          v-model="buttonEmployee"
          variant="primary"
          class="mt-1"
          style=""
          @click="getEmployeeSection()"
        >
          <span class="saj-button d-md-none d-lg-block">
            {{
              isEmployeeID === true ? $t("Employee No") : $t("Employee Name")
            }}
          </span>
        </b-button>
        <!-- Employee ID or Employee Name Button End -->
        <div v-if="!isEmployeeName">
          <!-- ID -->
          <b-form-group class="saj-title mt-1" :label="$t('Employee Number')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Employee Number')"
              rules="required"
            >
              <v-select
                v-model="selectedEmp"
                class="saj-title select-size-md"
                :selectable="
                  (option) => !allSelectedName.includes(option.value)
                "
                style="background: white"
                label="empNo"
                :placeholder="$t('Choose Number')"
                :disabled="
                  (selectedSubReg === null && !isLoading) ||
                  selectedSubReg === ''
                "
                :options="nameList"
                :value="nameList"
                :close-on-select="true"
                :loading="isLoading"
                @input="selectedNumber()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <!-- <b-form-input
              v-if="selectedEmp.value === undefined"
              v-model="current_empName"
              :placeholder="$t('Employee Name')"
              class="saj-text mt-1"
              disabled
            />-->
            <b-form-input 
              :placeholder="selectedEmp.length != 0 ? selectedEmp.text : $t('Employee Name')"
              class="saj-text mt-1"
              disabled
            />
          </b-form-group>
        </div>

        <div v-if="isEmployeeName">
          <!-- Name -->
          <b-form-group class="saj-title mt-1" :label="$t('Employee Name')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Employee Name')"
              rules="required"
            >
              <v-select
                v-model="selectedEmp"
                class="saj-title select-size-md"
                :selectable="
                  (option) => !allSelectedName.includes(option.value)
                "
                style="background: white"
                label="text"
                :placeholder="$t('Choose Name')"
                :disabled="
                  (selectedSubReg === null && !isLoading) ||
                  selectedSubReg === ''
                "
                :options="nameList"
                :value="nameList"
                :close-on-select="true"
                :loading="isLoading"
                @input="selectedNumber()"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            <!-- <b-form-input
              v-if="selectedEmp.value === undefined"
              v-model="current_empNo"
              :placeholder="$t('Employee Number')"
              class="saj-text mt-1"
              disabled
            /> -->
            <b-form-input
              v-model="selectedEmp.empNo"
              :placeholder="$t('Employee Number')"
              class="saj-text mt-1"
              disabled
            />
          </b-form-group>
        </div>
      </b-form-group>
    </validation-observer>
    <div class="d-flex justify-content-center" style="">
      <button
        class="btn btn-close m-1 saj-button"
        aria-label="Close modal"
        style="color: white; background: #ff0000"
        @click="close()"
      >
        {{ $t("Back") }}
      </button>
      <button
        class="btn m-1 saj-button"
        aria-label="submit modal"
        style="
          color: white;
          background: #0b103c !important;
          border-color: #0b103c !important;
        "
        @click="isUpdate ? updateHeadSection() : addHeadSection()"
      >
        {{ $t("Save") }}
      </button>
    </div>
  </div>
</template>
<script>
import { VBTooltip, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import vSelect from "vue-select";
import SAJToast from "@/component/saj-toastification.vue";
import { mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

export default {
  components: {
    vSelect,
    BFormGroup,
    BFormInput,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    isUpdate: {
      type: Boolean,
      default: false,
    },
    sectionId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      allSelectedName: [],
      nameList: [],
      selectedEmp: [],
      isEmployeeName: false,
      isEmployeeID: false,
      buttonEmployee: null,
      selectedSubReg: null,
      subList: [],
      isLoading: false,
      existingIds: [],
      current_empNo: null,
      current_empName: null,
      current_empId: null,
      required,
    };
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === "en";
    },
    ...mapGetters(["roles"]),
  },
  mounted() {
    this.getSubsidiary();
    if (this.isUpdate) {
      this.getEmployeeSection();
    }
  },
  methods: {
    getSubsidiary() {
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then((response) => {
        this.allbusinessUnit = response.data.data.subsidiaries;

        this.subList = this.allbusinessUnit.map((b) => ({
          text: b.subsidiary_name,
          value: b.id,
        }));
      });
    },
    getEmployeeSection() {
      this.isEmployeeID = !this.isEmployeeID;
      this.isEmployeeName = !this.isEmployeeName;

      if (this.sectionId != null) {
        const params = new URLSearchParams();

        params.append("head_of_section_id", this.sectionId);

        this.$axios
          .get(`${this.$baseUrl}/head_of_section/get_all_head_of_section`, {
            params,
          })
          .then((response) => {
            const allDetails = response.data.data.employee;
            const subId = allDetails.business_unit;
            this.current_empNo = allDetails.employee_number;
            this.current_empName = allDetails.full_name;
            this.current_empId = response.data.data.employee_id;

            this.selectedSubReg = subId;
            this.selectedEmp = {
              text: allDetails.full_name,
              value: allDetails.employee_id,
              empNo: allDetails.employee_number
            }
            // if (!this.isEmployeeName) {
            //   this.selectedEmp = this.current_empNo;
            // } else {
            //   this.selectedEmp = this.current_empNo;
            // }

            if (this.isUpdate) {
              this.getEmployeeName();
              this.selectedNumber();
            }
            // console.log(allDetails)
          });
      }
    },
    getEmployeeName() {
      // const params = new FormData()
      // params.append("subsidiary_id", this.id)
      this.nameList = [];
      this.isLoading = true;
      const params = new URLSearchParams();

      params.append("subsidiary_id", this.selectedSubReg);
      params.append("status", "active");

      this.$axios
        .get(`${this.$baseUrl}/employees/getAllEmployeesByGrade`, { params })
        .then((response) => {
          const allName = response.data.data;

          this.nameList = allName.map((item) => ({
            value: item.employee_id,
            empNo: item.employee_number,
            text: item.full_name,
          }));

          this.isLoading = false;
        });
    },

    selectedNumber() {
      if (this.selectedEmp === null) {
        // eslint-disable-next-line no-param-reassign
        this.selectedEmp = [];
        // return this.selectedEmp
      }

      // return this.selectedEmp;
    },

    addHeadSection() {
      this.$refs.validateSection.validate().then((success) => {
        if (success) {
          const data = new FormData();
          data.append("employee_id", this.selectedEmp.value);

          this.$axios
            .post(
              `${this.$baseUrl}/head_of_section/store_head_of_section`,
              data
            )
            .then(() => {
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: `${this.$t(`Successfully set as Head of Section`)}.`,
                    icon: "CheckIcon",
                    variant: "success",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: "info",
                }
              );
              this.$emit("close");
              this.$emit("reload");
            })
            .catch((err) => {
              const msg = err.response.data.data;
              this.$toast(
                {
                  component: SAJToast,
                  props: {
                    title: this.isEnglish ? msg.bi_msg : msg.bm_msg,
                    icon: "XIcon",
                    variant: "danger",
                    iconBg: "#e80202",
                    titleColor: "#000",
                  },
                },
                {
                  position: "top-right",
                  type: "info",
                }
              );
              this.$emit("close");
            });
        }
      });
    },

    updateHeadSection() {
      const data = new FormData();
      console.log('>> update', this.selectedEmp.value)
      if (this.selectedEmp.value === undefined) {
        data.append("employee_id", this.current_empId);
      } else {
        data.append("employee_id", this.selectedEmp.value);
      }
      data.append("head_of_section_id", this.sectionId);
      this.$axios
        .post(`${this.$baseUrl}/head_of_section/update_head_of_section`, data)
        .then(() => {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t(`Head of Section successfully updated`)}.`,
                icon: "CheckIcon",
                variant: "success",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: "info",
            }
          );
          this.$emit("close");
          this.$emit("reload");
        })
        .catch((err) => {
          const msg = err.response.data.data;
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: this.isEnglish ? msg.bi_msg : msg.bm_msg,
                icon: "XIcon",
                variant: "danger",
                iconBg: "#e80202",
                titleColor: "#000",
              },
            },
            {
              position: "top-right",
              type: "info",
            }
          );
          this.$emit("close");
        });
    },

    close() {
      this.$emit("close");
    },
  },
};
</script>
