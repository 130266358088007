<template>
  <div>
    <div
      class="card"
      style="background: #ffffff;
              border-radius: 10px;
              "
    >
      <div
        class="pl-3 pr-3 pt-2"
      >
        <div class="">
          <span class="saj-title">
            {{ $t("Search Filter") }}
          </span>
        </div>
        <div class="row align-items-end mb-1">
          <!-- if admin -->
          <!-- <template v-if="roles.selectedRole === 1"> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Subsidiary") }}:</label>
            <v-select
              v-model="selectedSub"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Subsidiary')"
              :options="subList"
              :reduce="sub => sub.value"
              label="text"
              :disabled="roles.selectedRole === 6"
              @input="getData()"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Department") }}:</label>
            <!-- <b-form-select
              v-model="selectedDepartment"
              :options="departmentList"
              :value="departmentList"
              size="sm"
              class="saj-text"
              :disabled="selectedSub === null"
            /> -->
            <v-select
              v-model="selectedDepartment"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Department')"
              :options="departmentList"
              :reduce="dept => dept.value"
              label="text"
              :disabled="isSub"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Work Location") }}:</label>
            <v-select
              v-model="selectedWork"
              class="saj-text select-size-sm"
              style="background: white"
              :placeholder="$t('Choose Work Location')"
              :options="WorkList"
              :reduce="work => work.value"
              label="text"
              :disabled="isSub"
            />
          </b-col>
          <!-- </template> -->
          <b-col
            cols="12"
            md="4"
            class="mb-md-0"
          >
            <label class="saj-text">{{ $t("Employee Number") }}:</label>
            <!-- <b-form-select
              v-model="selectedEmployeeID"
              :options="empIDList"
              :value="empIDList"
              size="sm"
              class="saj-text"
            /> -->
            <b-form-input
              v-model="selectedEmployeeID"
              :placeholder="$t('Search Employee Number')"
              size="sm"
              class="saj-text"
            />
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 pt-1"
          >
            <label class="saj-text">{{ $t("Search Name") }}:</label>
            <b-form-input
              id="icons-search"
              v-model="searchname"
              :placeholder="$t('Search name')"
              size="sm"
            />
          </b-col>
          <div class="d-flex">
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="d-flex justify-content-center"
                style=""
                @click="show=true, getSectionHead()"
              >

                <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
              </b-button>
            </b-col>
            <b-col>
              <b-button
                md="4"
                variant="primary"
                class="saj-title d-flex justify-content-center"
                block
                style="
          "
                @click="clearButton()"
              >
                <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
              </b-button>
            </b-col>
          </div>

        </div>
      </div>
    </div>
    <div
      class="card"
      style="background: #ffffff;
              border-radius: 10px;
              "
    >
      <div class="mt-2 pl-3 pr-3 pt-2">

        <div class="d-flex justify-content-end">
          <b-button
            v-b-modal.add-section
            md="4"
            class="p-1 saj-button ml-1 mb-1"
            style=""
            variant="primary"
          >

            {{ $t('Add Head of Section') }}
          </b-button>
        </div>
        <b-overlay
          :show="show"
          rounded="sm"
        >
          <template #overlay>
            <div class="d-flex flex-column align-items-center justify-content-center">
              <b-spinner
                variant="primary"
                label="Spinning"
              />
              <p>{{ $t('Fetching Data') }}... </p>
            </div>
          </template>

          <div class="row pl-1 pr-1">
            <p class="saj-title">
              {{ $t("Head of Section List") }}
            </p>
            <b-table
              class="styleTable"
              style=""
              :items="sectionList"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              label-size="lg"
              bordered
              show-empty
              responsive
            >
              <template #head()="data">
                <span
                  class="saj-text d-flex justify-content-center"
                >{{ $t(data.label) }}</span>
              </template>
              <template #cell(index)="data">
                <div
                  class="saj-text d-flex justify-content-center"
                >
                  {{ currentPage === 1 ? data.index + 1 : (data.index + 1)+((currentPage-1) *10) }}
                </div>
              </template>
              <template #empty="">
                <h4 style="text-align: center; font-style: italic">
                  {{ $t('There are no records to show') }}
                </h4>
              </template>
              <template #cell(emp_no)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  {{ data.item.employee.employee_number }}
                </div>
              </template>
              <template #cell(employee_name)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  {{ data.item.employee.full_name }}
                </div>
              </template>
              <template #cell(department)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  {{ data.item.employee.department.name }}
                </div>
              </template>
              <template #cell(branch)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  {{ data.item.employee.branch.name }}
                </div>
              </template>
              <template #cell(total_emp)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  {{ data.item.employee_list_count }}
                </div>
              </template>
              <template #cell(action)="data">
                <div
                  class="saj-text"
                  style=""
                >
                  <div class="d-flex justify-content-center">

                    <feather-icon
                      v-b-tooltip.hover.bottom="$t('View Employee Assigned')"
                      icon="EyeIcon"
                      color="purple"
                      class="hoverIcon"
                      size="25"
                      @click="viewEmployee(data.item.id)"
                    />
                    <feather-icon
                      v-b-modal.edit-section
                      v-b-tooltip.hover.bottom="$t('Edit')"
                      icon="EditIcon"
                      size="22"
                      style="color: orange;"
                      class="ml-1"
                      @click="selectedId = data.item.id"
                    />
                    <feather-icon
                      v-b-modal.delete-section
                      v-b-tooltip.hover.bottom="$t('Remove')"
                      icon="XIcon"
                      color="red"
                      size="25"
                      class="ml-1"
                      @click="selectedId = data.item.id"
                    />
                  </div>
                </div>
              </template>
            </b-table>
          </div>
          <b-row v-if="sectionList.length > 0">
            <b-col>
              <span
                class="saj-text"
                align="start"
              >
                {{ $t("Showing") }} {{ rows > perPage*currentPage ? perPage*currentPage : rows }} {{ $t("from") }} {{ rows }} {{ $t("entries") }}
                <!-- {{ $t("Showing") }} 1 {{ $t("to") }} 2 {{ $t("of") }} 3 {{ $t("entries") }} -->
              </span>
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                pills
                class="saj-text mb-1 justify-content-end"
              />
            </b-col>
          </b-row>
        </b-overlay>
        <b-modal
          id="add-section"
          :hide-header="true"
          :hide-footer="true"
          :centered="true"
          :no-close-on-backdrop="true"
          size="lg"
          class="p-1"
        >

          <head-modal
            @close="$bvModal.hide('add-section')"
            @reload="getSectionHead()"
          />

        </b-modal>
        <b-modal
          id="edit-section"
          :hide-header="true"
          :hide-footer="true"
          :centered="true"
          :no-close-on-backdrop="true"
          size="lg"
          class="p-1"
        >

          <head-modal
            :is-update="true"
            :section-id="selectedId"
            @close="$bvModal.hide('edit-section')"
            @reload="getSectionHead()"
          />
        </b-modal>
        <b-modal
          id="delete-section"
          :hide-footer="true"
          :hide-header="true"
          :centered="true"
          :no-close-on-backdrop="true"
          size="lg"
          class="p-1"
        >
          <alertModal
            confirm-btn="Yes"
            cancel-btn="No"
            :title="$t('Are you sure to delete this Head of Section?')"
            @confirm="deleteSection()"
            @cancel="$bvModal.hide('delete-section')"
          />
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import {
  // BFormRadio,
  // BModal,
  BFormInput,
  // BFormSelect,
  BRow,
  BTable,
  BCol,
  BButton,
  BOverlay,
  // BButtonGroup,
  BPagination,
  BSpinner,
  VBTooltip,
  BModal,
  // BFormGroup,

} from "bootstrap-vue"
// import SAJToast from '@/component/saj-toastification.vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import alertModal from "@/pages/alert-modal.vue"
import SAJToast from "@/component/saj-toastification.vue"
import headModal from './head_section_modal.vue'

export default {
  components: {
    // BModal,
    // BFormRadio,
    BFormInput,
    // BFormSelect,
    BRow,
    BTable,
    BCol,
    BButton,
    BOverlay,
    // BButtonGroup,
    BPagination,
    BSpinner,
    vSelect,
    BModal,
    // BFormGroup,
    headModal,
    alertModal,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  data(){
    return {
      allSelectedName: [],
      nameList: [{
        value: null,
        empNo: null,
        text: null,
      }],
      selectedEmp: [{
        value: null,
        text: null,
        empNo: null,
      }],
      isEmployeeName: false,
      isEmployeeID: false,
      buttonEmployee: null,
      selectedSubReg: null,
      selectedWork: null,
      searchname: null,
      WorkList: [],
      isSub: true,
      subList: [],
      selectedSub: null,
      departmentList: [],
      selectedDepartment: null,
      empIDList: [{ value: null, text: `${this.$t('Choose Employee Id')}`, disabled: true }],
      selectedEmployeeID: null,
      // filter end
      sectionList: [],
      subsidiaryList: [],
      subsidiary_id: null,
      tabIndex: 1,
      show: true,
      currentPage: 1,
      perPage: 10,
      rows: 0,
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'emp_no', label: 'Employee Number', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'employee_name', label: 'Employee Name', thClass: 'text-center',
        },
        {
          key: 'department', label: 'Department', thClass: 'text-center',
        },
        {
          key: 'branch', label: 'Work Location', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'total_emp', label: 'Total Employee Assigned', thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'action', label: 'Action', tdClass: 'text-center',
        },
      ],
      allbusinessUnit: null,
      alldepartment: null,
      exporting: false,
      isLoading: false,
      selectedId: null,
    }
  },

  computed: {
    ...mapGetters(['roles']),
  },
  watch: {
    $route: {
      handler(){
        this.getSub_Id()
      },

    },
    // eslint-disable-next-line func-names
    '$i18n.locale': function () {
    },
  },

  mounted(){
    this.getSubsidiary()
    this.getSectionHead()
  },

  methods: {
    getData(){
      if (this.selectedSub !== null) {
        this.getDepartment()
        this.getWorkLocation()
      } else {
        this.isSub = true
      }

      if (this.selectedWork !== null){
        this.selectedWork = null
      }
      if (this.selectedDepartment !== null){
        this.selectedDepartment = null
      }
    },
    getWorkLocation(){
      this.$axios.get(`${this.$baseUrl}/branch/get_branch_by_company?company_id=${this.selectedSub}`).then(res => {
        const work = res.data.data
        this.WorkList = work.map(x => ({
          text: x.name,
          value: x.id,
        }))
      })
    },
    clearButton(){
      this.selectedDepartment = null
      this.selectedEmployeeID = null
      this.selectedSub = null
      this.searchname = null
      this.selectedWork = null
      // console.log('role', this.roles.selectedRole)
      this.show = true
      this.currentPage = 1
      this.getSectionHead()
    },
    getSubsidiary(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(response => {
        this.allbusinessUnit = response.data.data.subsidiaries
        // this.allbusinessUnit.forEach(b => {
        //   this.businessUnitList.push({
        //     text: b.subsidiary_name,
        //     value: b.id,

        //   })
        // })

        this.subList = this.allbusinessUnit.map(b => ({
          text: b.subsidiary_name,
          value: b.id,
        }))
      })
    },
    getDepartment(){
      // console.log('sub', this.selectedSub)
      this.$axios.get(`${this.$baseUrl}/departments/get_departments_by_subsidiary?subsidiary_id=${this.selectedSub}`).then(response => {
        const result = response.data
        // console.log('res', result)
        if (result.success){
          this.alldepartment = response.data.data

          this.departmentList = this.alldepartment.map(d => ({
            text: d.name,
            value: d.id,
          }))

          // if (this.selectedDepartment !== null){
          this.isSub = false
          this.selectedDepartment = null
          // }
        } else {
          this.departmentList = []
          this.selectedDepartment = null
        }
      })
    },

    viewEmployee(userId){
      // console.log('user', userId)
      this.$router.push({ name: 'Section-Details', params: { userId } })
    },

    getSectionHead(){
      const params = new URLSearchParams()

      if (this.selectedSub !== null){
        params.append('subsidiary_id', this.selectedSub)
      }
      if (this.selectedDepartment !== null){
        params.append('department_id', this.selectedDepartment)
      }
      if (this.selectedWork !== null){
        params.append('work_location', this.selectedWork)
      }
      if (this.selectedEmployeeID !== null){
        params.append('employee_number', this.selectedEmployeeID)
      }
      if (this.searchname !== null){
        params.append('search_name', this.searchname)
      }

      this.$axios.get(`${this.$baseUrl}/head_of_section/get_all_head_of_section`, { params }).then(response => {
        const sections = response.data.data

        if (sections.length > 0){
          this.sectionList = sections
          this.rows = this.sectionList.length
          this.show = false
        } else {
          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('No Section Head found')}!`,
                icon: "InfoIcon",
                iconBg: '#53b7db',
                variant: "success",
                titleColor: "#000",
                iconColor: 'white',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )

          this.sectionList = []
          this.show = false
        }
      }).catch(() => {
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('No Section Head found')}!`,
              icon: "InfoIcon",
              iconBg: '#53b7db',
              variant: "success",
              titleColor: "#000",
              iconColor: 'white',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )

        this.sectionList = []
        this.show = false
      })
    },

    deleteSection(){
      const data = new FormData()

      data.append('head_of_section_id', this.selectedId)

      this.$axios.post(`${this.$baseUrl}/head_of_section/delete_head_of_section`, data).then(() => {
        this.getSectionHead()
        this.$bvModal.hide("delete-section")
        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully deleted')}!`,
              icon: "Trash2Icon",
              iconBg: '#e80202',
              variant: "success",
              titleColor: "#000",
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
  },
}
</script>
<style scoped>
.Margin {
  margin : 0.5rem;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
}

:hover::-webkit-scrollbar-thumb {

    /* background-color: #a0a0a5 !important; */
    background-color: rgb(255, 0, 0)
}

::-webkit-scrollbar-thumb:hover {
    border: 2px solid #bbb9b9 !important;
}
</style>
