<template>
  <div
    class="d-flex flex-column justify-content-center align-items-center p-1"
  >
    <div class="d-flex flex-column align-items-center">
      <feather-icon
        :icon="icon"
        :style="`color: ${iconColor}`"
        size="56"
      />
      <h2
        class="mt-1"
        style="text-align: center;"
      >
        {{ $t(title) }}
      </h2>
      <p style="font-size: 1rem">
        {{ subtitle }}
      </p>
    </div>
    <div class="mt-1">
      <b-button
        v-if="showConfirm"
        class="mr-1 saj-button"
        style="background-color: rgb(11, 16, 60) !important;"
        @click="confirm()"
      >
        {{ $t(confirmBtn) }}
      </b-button>
      <b-button
        v-if="showCancel"
        class="confirmButton saj-button"
        @click="cancel()"
      >
        {{ $t(cancelBtn) }}
      </b-button>
    </div>
  </div>

</template>
<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,

  },
  props: {
    title: {
      type: String,
      default: 'Are you sure to delete?',
    },
    subtitle: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'AlertCircleIcon',
    },
    iconColor: {
      type: String,
      default: '#FF9F43',
    },
    confirmBtn: {
      type: String,
      default: 'Submit',
    },
    cancelBtn: {
      type: String,
      default: 'Cancel',
    },
    showConfirm: {
      type: Boolean,
      default: true,
    },
    showCancel: {
      type: Boolean,
      default: true,
    },
  },
  data(){
    return {

    }
  },
  mounted(){

  },
  methods: {
    cancel(){
      this.$emit('cancel')
    },
    confirm(){
      this.$emit('confirm')
    },

  },
}
</script>
<style scoped>
.confirmButton {
    border-color: red !important;
    background-color:red !important;
    color: white !important;
}
.confirmButton:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #e5eaee;
}
</style>
