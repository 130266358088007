var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-1"},[_c('p',{staticClass:"d-flex justify-content-center saj-header"},[_vm._v(" "+_vm._s(_vm.isUpdate ? _vm.$t("Update Head of Section") : _vm.$t("Add New Head of Section"))+" ")]),_c('validation-observer',{ref:"validateSection"},[_c('label',{staticClass:"saj-title"},[_vm._v(_vm._s(_vm.$t("Subsidiary"))+":")]),_c('validation-provider',{attrs:{"name":_vm.$t('Subsidiary'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"saj-text select-size-md",staticStyle:{"background":"white"},attrs:{"placeholder":_vm.$t('Choose Subsidiary'),"options":_vm.subList,"reduce":function (sub) { return sub.value; },"label":"text","disabled":_vm.roles.selectedRole === 1 && _vm.isUpdate},on:{"input":function($event){return _vm.getEmployeeName()}},model:{value:(_vm.selectedSubReg),callback:function ($$v) {_vm.selectedSubReg=$$v},expression:"selectedSubReg"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-form-group',{staticClass:"saj-title mt-1",attrs:{"label":_vm.$t('Choose Employee Number / Name')}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.getEmployeeSection()}},model:{value:(_vm.buttonEmployee),callback:function ($$v) {_vm.buttonEmployee=$$v},expression:"buttonEmployee"}},[_c('span',{staticClass:"saj-button d-md-none d-lg-block"},[_vm._v(" "+_vm._s(_vm.isEmployeeID === true ? _vm.$t("Employee No") : _vm.$t("Employee Name"))+" ")])]),(!_vm.isEmployeeName)?_c('div',[_c('b-form-group',{staticClass:"saj-title mt-1",attrs:{"label":_vm.$t('Employee Number')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Employee Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"saj-title select-size-md",staticStyle:{"background":"white"},attrs:{"selectable":function (option) { return !_vm.allSelectedName.includes(option.value); },"label":"empNo","placeholder":_vm.$t('Choose Number'),"disabled":(_vm.selectedSubReg === null && !_vm.isLoading) ||
                _vm.selectedSubReg === '',"options":_vm.nameList,"value":_vm.nameList,"close-on-select":true,"loading":_vm.isLoading},on:{"input":function($event){return _vm.selectedNumber()}},model:{value:(_vm.selectedEmp),callback:function ($$v) {_vm.selectedEmp=$$v},expression:"selectedEmp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,701376358)}),_c('b-form-input',{staticClass:"saj-text mt-1",attrs:{"placeholder":_vm.selectedEmp.length != 0 ? _vm.selectedEmp.text : _vm.$t('Employee Name'),"disabled":""}})],1)],1):_vm._e(),(_vm.isEmployeeName)?_c('div',[_c('b-form-group',{staticClass:"saj-title mt-1",attrs:{"label":_vm.$t('Employee Name')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Employee Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"saj-title select-size-md",staticStyle:{"background":"white"},attrs:{"selectable":function (option) { return !_vm.allSelectedName.includes(option.value); },"label":"text","placeholder":_vm.$t('Choose Name'),"disabled":(_vm.selectedSubReg === null && !_vm.isLoading) ||
                _vm.selectedSubReg === '',"options":_vm.nameList,"value":_vm.nameList,"close-on-select":true,"loading":_vm.isLoading},on:{"input":function($event){return _vm.selectedNumber()}},model:{value:(_vm.selectedEmp),callback:function ($$v) {_vm.selectedEmp=$$v},expression:"selectedEmp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,245607590)}),_c('b-form-input',{staticClass:"saj-text mt-1",attrs:{"placeholder":_vm.$t('Employee Number'),"disabled":""},model:{value:(_vm.selectedEmp.empNo),callback:function ($$v) {_vm.$set(_vm.selectedEmp, "empNo", $$v)},expression:"selectedEmp.empNo"}})],1)],1):_vm._e()],1)],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('button',{staticClass:"btn btn-close m-1 saj-button",staticStyle:{"color":"white","background":"#ff0000"},attrs:{"aria-label":"Close modal"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]),_c('button',{staticClass:"btn m-1 saj-button",staticStyle:{"color":"white","background":"#0b103c !important","border-color":"#0b103c !important"},attrs:{"aria-label":"submit modal"},on:{"click":function($event){_vm.isUpdate ? _vm.updateHeadSection() : _vm.addHeadSection()}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }